import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import AltPageLayout, { isFirstSectionHasLabel } from 'Components/layout/AltPageLayout'
import Menu from 'Components/layout/Menu'
import ProjectPreview from 'Components/ProjectPreview/ProjectPreview'
import DynamicSections, {
  getDynamicSectionThemes,
} from 'Components/sections/DynamicSections'
import { ROUTES } from 'Utils/routing'
import { HeaderThemeOnScrollChanger } from 'Components/layout/Header'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'
import type { PageDataType } from 'Typings/api'

const PortfolioPage = (props: PageDataType) => {
  const { data, path } = props

  const { t } = useTranslation()

  useMaybeChangeLanguageOnInit()

  // console.log('PortfolioPage', data)

  if (!data) {
    return null
  }

  return (
    <>
      <Menu />
      <AltPageLayout
        seo={data.seo}
        path={path}
        title={data.heading}
        subTitle={data.sub_heading}
        firstSectionHasLabel={isFirstSectionHasLabel(data.dynamic)}
      >
        <HeaderThemeOnScrollChanger
          themes={[
            ...getDynamicSectionThemes(data.dynamic),
            ...data.projects.map(() => 'dark'),
          ].map((i) => (i === 'light' ? '' : i))} // На светлой теме остаётся базовым цветом
        />
        <DynamicSections sections={data.dynamic} />
        {data.projects.map((i: any, index: number) => (
          <ProjectPreview
            key={i.project.id}
            project={i.project}
            link={ROUTES.project(i.project.slug)}
            linkText={t('more') ?? ''}
            index={index + 1}
            totalCount={data.projects.length}
            Component="section"
          />
        ))}
      </AltPageLayout>
    </>
  )
}

export default PortfolioPage
